export const getValuesFromPesel = (pesel: string) => {
  const arrayMonthOfBirth = pesel.slice(2, 4).split('')
  const intArrOfMonth = arrayMonthOfBirth.map((month) => parseInt(month, 10))
  const monthOfBirth =
    intArrOfMonth[0] > 1 ? (intArrOfMonth[0] - 2) * 10 + intArrOfMonth[1] : intArrOfMonth[0] * 10 + intArrOfMonth[1]
  const yearOfBirth =
    intArrOfMonth[0] > 1 ? parseInt(`20${pesel.slice(0, 2)}`, 10) : parseInt(`19${pesel.slice(0, 2)}`, 10)
  const dayOfBirth = parseInt(pesel.slice(4, 6), 10)
  const genderSlice = parseInt(pesel.slice(9, 10), 10)
  const gender = genderSlice % 2 === 0 ? 'K' : 'M'

  const birthDate = `${yearOfBirth}-${monthOfBirth < 10 ? `0${monthOfBirth}` : monthOfBirth}-${
    dayOfBirth < 10 ? `0${dayOfBirth}` : dayOfBirth
  }`

  return { birthDate, gender }
}

export const validatePesel = (pesel: string) => {
  const controlDigit = parseInt(pesel.slice(-1), 10)

  const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]

  const arrayOfPeselNumbers = pesel.split('')
  const withoutLastDigit = arrayOfPeselNumbers.slice(0, -1)
  const lastDigOfMultByCorspndWeight = withoutLastDigit.map(
    (peselPart, index) => (parseInt(peselPart, 10) * weights[index]) % 10
  )
  const lastDigOfSumOfWeightedNumbers =
    lastDigOfMultByCorspndWeight.reduce((sum, controlSumPart) => sum + controlSumPart, 0) % 10

  const controlSum = (10 - lastDigOfSumOfWeightedNumbers) % 10

  return controlSum === controlDigit
}
