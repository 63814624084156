import { ComponentStory, ComponentMeta } from '@storybook/react'
import { YEAR_MONTH_DAY } from 'common/constants'
import dayjs from 'dayjs'
import { calcDateMonthFromNow, Calendar } from '../components/Calendar/Calendar'

export default {
  component: Calendar,
  title: 'Visit Flow/Calendar',
  argTypes: { setMonthToFetch: { action: 'Clicked on new month' } }
} as ComponentMeta<typeof Calendar>

const Template: ComponentStory<typeof Calendar> = (args) => <Calendar {...args} />

export const Default = Template.bind({})

const today = dayjs(new Date())
const tommorow = dayjs(new Date().setDate(new Date().getDate() + 1))

Default.args = {
  maxDate: calcDateMonthFromNow(),
  value: today,
  availableTerms: [
    {
      eventTypeId: 7,
      date: tommorow.format(YEAR_MONTH_DAY),
      availableHours: []
    }
  ]
}
