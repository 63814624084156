import { TFuncKey, Trans } from 'react-i18next'
import { makeStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

interface DualColorChoiceLinkProps {
  /**
   * Translation path of the link text
   */
  translationPath: string
  /**
   * Path that the link will redirect to
   */
  routingPath: string
  className?: string
}

/**
 * Component that renders a link with two different colors
 */
export const DualColorChoiceLink = ({
  translationPath,
  routingPath,
  className = ''
}: DualColorChoiceLinkProps): JSX.Element => {
  const classes = useStyle()

  return (
    <Typography className={className}>
      <Trans i18nKey={translationPath as TFuncKey}>
        Normal text
        <Link to={routingPath} className={classes.link}>
          Colorful link text
        </Link>
      </Trans>
    </Typography>
  )
}

const useStyle = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  }
}))
