import { makeStyles } from '@material-ui/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { Checkbox, FormTextField, Button, FormTextArea, HrefText } from 'common/components'
import { useMobileScreenSizeDetector } from 'common/hooks'
import { useEffect } from 'react'
import { ContactFormData, contactFormSchema } from 'features/landingSections/types'
import { RoutesDefinition } from 'routing/constants/RoutesDefinition'

interface ContactFormProps {
  onSubmit: SubmitHandler<ContactFormData>
  isSubmitError: boolean
  isProcessing?: boolean
  isSuccess?: boolean
}

export function ContactForm({ isProcessing, onSubmit, isSubmitError, isSuccess }: ContactFormProps): JSX.Element {
  const classes = useStyle()
  const { t } = useTranslation()
  const isMobile = useMobileScreenSizeDetector()

  const formMethods = useForm<ContactFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(contactFormSchema),
    defaultValues: {
      name: '',
      email: '',
      message: '',
      termsAndConditions: false
    }
  })

  useEffect(() => {
    if (isSubmitError) {
      formMethods.setError('message', { message: 'errors.formError' })
    }

    if (isSuccess) {
      formMethods.reset({
        name: '',
        email: '',
        message: '',
        termsAndConditions: false
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitError, isSuccess])

  return (
    <FormProvider {...formMethods}>
      <form className={classes.form} onSubmit={formMethods.handleSubmit(onSubmit)}>
        <h3 className={classes.title}>{t('landingPage.contactSection.form.title')}</h3>
        <FormTextField name="name" type="text" placeholder={t('landingPage.contactSection.form.name.placeholder')} />
        <FormTextField name="email" type="email" placeholder={t('landingPage.contactSection.form.email.placeholder')} />
        {isMobile ? (
          <FormTextArea
            minRows={5}
            name="message"
            type="text"
            placeholder={t('landingPage.contactSection.form.message.placeholder')}
          />
        ) : (
          <FormTextField
            name="message"
            type="text"
            placeholder={t('landingPage.contactSection.form.message.placeholder')}
          />
        )}

        <Button
          isLoading={isProcessing}
          isSuccess={isSuccess}
          color="primary"
          fullWidth
          variant="contained"
          type="submit"
        >
          {t('landingPage.contactSection.form.submitButton')}
        </Button>
        <Checkbox
          className={classes.checkBox}
          name="termsAndConditions"
          label={
            <Trans
              i18nKey="landingPage.contactSection.form.termsAndConditions.label"
              components={{ lnk: <HrefText className={classes.linkText} href={RoutesDefinition.terms} /> }}
            />
          }
        />
      </form>
    </FormProvider>
  )
}

const useStyle = makeStyles((theme) => ({
  form: {
    backgroundColor: theme.customColors.backgroundGray,
    padding: `${theme.spacing(6)} ${theme.spacing(5)}`,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '2rem',

    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(4)} ${theme.spacing(2)}`
    },

    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(6)} 0`
    },

    '& > div': {
      margin: `0 0 ${theme.spacing(4)} 0`,

      [theme.breakpoints.down('sm')]: {
        margin: `0 0 ${theme.spacing(2)} 0`
      }
    }
  },
  linkText: {
    color: 'inherit'
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 700,
    color: theme.palette.primary.main,
    margin: `0 0 ${theme.spacing(4)} 0`
  },
  checkBox: {
    '& > .MuiFormControlLabel-label': {
      color: `${theme.customColors.white} !important`
    },
    '& .MuiSvgIcon-root': {
      color: `${theme.customColors.lightGrey} !important`
    }
  }
}))
