import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, makeStyles } from '@material-ui/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Checkbox, FormTextField, Button, MaskedInput, HrefText } from 'common/components'
import { StartingContactDataForm, startingContactDataForm } from 'features/survey/types'
import { phoneMask } from 'common/constants'
import { useUser } from 'state'
import { RoutesDefinition } from 'routing/constants/RoutesDefinition'
import { triggerLeadEvent } from 'common/utils/gtm'

interface ContactDataForm {
  onSubmit: SubmitHandler<StartingContactDataForm>
  isProcessing?: boolean
  isSuccess: boolean
  isError: boolean
}

export const DataForm = ({ onSubmit, isProcessing, isSuccess, isError }: ContactDataForm): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { updateUserState } = useUser()

  useEffect(() => {
    if (isError) {
      formMethods.setError('ageDeclaration', { message: 'errors.formError' })
    }

    if (isSuccess) {
      const { firstName, lastName, phone, email } = formMethods.getValues()
      triggerLeadEvent({ first_name: firstName, last_name: lastName, phone, email })
      updateUserState({ firstName, lastName, phone, email })
      history.push(RoutesDefinition.surveyQuestionnaire)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess])

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(startingContactDataForm),
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      termsAndConditions: false,
      ageDeclaration: false
    }
  })

  return (
    <FormProvider {...formMethods}>
      <form className={classes.form} onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container className={classes.inputsWrapper}>
          <FormTextField
            name="firstName"
            type="text"
            label={t('surveyPage.contactDataForm.form.name.label')}
            placeholder={t('surveyPage.contactDataForm.form.name.placeholder')}
          />
          <FormTextField
            name="lastName"
            type="text"
            label={t('surveyPage.contactDataForm.form.surname.label')}
            placeholder={t('surveyPage.contactDataForm.form.surname.placeholder')}
          />
          <MaskedInput
            name="phone"
            type="tel"
            label={t('surveyPage.contactDataForm.form.phoneNumber.label')}
            placeholder={t('surveyPage.contactDataForm.form.phoneNumber.placeholder')}
            mask={phoneMask}
            defaultValue=""
          />
          <FormTextField
            name="email"
            type="email"
            label={t('surveyPage.contactDataForm.form.email.label')}
            placeholder={t('surveyPage.contactDataForm.form.email.placeholder')}
          />
        </Grid>
        <Grid item className={classes.checkboxContainer}>
          <Checkbox
            name="termsAndConditions"
            label={
              <Trans
                i18nKey="surveyPage.contactDataForm.form.termsAndConditions"
                components={{ lnk: <HrefText className={classes.linkText} href={RoutesDefinition.terms} /> }}
              />
            }
          />
          <Checkbox name="ageDeclaration" label={t('surveyPage.contactDataForm.form.ageDeclaration')} />
        </Grid>
        <Grid item className={classes.btnContainer}>
          <Button color="primary" fullWidth variant="contained" type="submit" disabled={isProcessing}>
            {t('surveyPage.contactDataForm.form.submitButton')}
          </Button>
        </Grid>
      </form>
    </FormProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  linkText: {
    color: 'inherit'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  inputsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',

    '& > div': {
      padding: `0 ${theme.spacing(1)}`,
      margin: `0 0 ${theme.spacing(2)}`
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  btnContainer: {
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    }
  }
}))
