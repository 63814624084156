import { apiClient } from 'common/utils/api'
import * as z from 'zod'

const accountDetailsResponseSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string(),
  role: z.string(),
  created: z.string(),
  updated: z.string().nullish(),
  isVerified: z.boolean(),
  isNewUser: z.boolean()
})

export type AccountDetailsRequestResponse = z.TypeOf<typeof accountDetailsResponseSchema>

export const getAccountDetails = async (token: string, id: string): Promise<AccountDetailsRequestResponse> => {
  const response = await apiClient(token).get(`Accounts/${id}`).json()

  return accountDetailsResponseSchema.parse(response)
}
