import { Box, CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import { CloseIcon, SuccessIcon } from 'common/components'

export interface PendingStepProps {
  header: string
  isSuccess: boolean
  isLoading: boolean
  isError?: boolean
}

export const PendingStep = ({ isSuccess, isLoading, header, isError }: PendingStepProps) => {
  const isPending = !isLoading && !isSuccess && !isError
  const isCompleted = !isLoading && !isPending
  const classes = useStyle({ isPending, isError })

  const icon = isError ? <CloseIcon className={classes.closeIcon} /> : <SuccessIcon className={classes.successIcon} />

  return (
    <Box className={classes.stepWrapper}>
      <Typography variant="body1">{header}</Typography>
      {isCompleted && icon}
      {(isLoading || isPending) && (
        <CircularProgress value={100} variant={isPending ? 'determinate' : 'indeterminate'} size="30px" />
      )}
    </Box>
  )
}

const useStyle = makeStyles<Theme, { isPending: boolean; isError?: boolean }>((theme) => ({
  stepWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,

    '& > p': {
      color: (props) => (props.isPending ? theme.customColors.opaqueGrey : theme.customColors.fontGray),
      margin: `0 ${theme.spacing(6)} 0 0`
    },

    '& svg': {
      fill: theme.palette.primary.main,
      color: (props) => (props.isPending ? theme.customColors.opaqueGrey : theme.palette.primary.main)
    }
  },
  successIcon: {
    height: '30px',
    width: '30px'
  },
  closeIcon: {
    height: '30px',
    width: '30px',
    color: 'red !important',
    fill: 'red !important'
  }
}))
