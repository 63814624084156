import { SVGProps } from 'react'

export const SuccessIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} width="137" height="137" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="68.5" cy="68.5" r="66" />
    <path
      d="M87.4017 48.5617C88.4016 47.6097 89.7323 47.0835 91.1129 47.0939C92.4935 47.1043 93.816 47.6507 94.8014 48.6177C95.7868 49.5847 96.358 50.8967 96.3945 52.2768C96.431 53.657 95.9299 54.9973 94.997 56.015L66.6744 91.4361C66.1873 91.9607 65.5996 92.3816 64.9461 92.6738C64.2927 92.966 63.5871 93.1235 62.8714 93.1367C62.1557 93.15 61.4448 93.0188 60.781 92.7509C60.1172 92.4831 59.5142 92.0842 59.0081 91.5781L40.2257 72.7957C39.7026 72.3083 39.2831 71.7205 38.9921 71.0675C38.7012 70.4144 38.5447 69.7095 38.5321 68.9946C38.5195 68.2798 38.651 67.5698 38.9187 66.9069C39.1865 66.244 39.585 65.6418 40.0906 65.1362C40.5961 64.6307 41.1983 64.2322 41.8612 63.9644C42.5241 63.6966 43.2341 63.5651 43.949 63.5777C44.6638 63.5904 45.3688 63.7468 46.0218 64.0378C46.6749 64.3288 47.2626 64.7483 47.75 65.2714L62.6141 80.1283L87.2668 48.7179C87.3112 48.6632 87.3586 48.6111 87.4088 48.5617H87.4017Z"
      fill="white"
    />
  </svg>
)
