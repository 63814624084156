import { YEAR_MONTH_DAY } from 'common/constants'
import * as z from 'zod'
import { apiClient } from 'common/utils/api'
import dayjs from 'dayjs'
import { findIndex } from 'lodash'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

const freeTermsSchema = z.object({
  eventTypeId: z.number(),
  monthNumber: z.number(),
  jwtToken: z.string()
})

export type FreeTermsData = z.TypeOf<typeof freeTermsSchema>

const freeTermsResponseSchema = z
  .object({
    type_id: z.number(),
    doctor_id: z.number(),
    time: z.string(),
    duration: z.number()
  })
  .array()

export type FreeTermsRequestResponse = z.TypeOf<typeof freeTermsResponseSchema>

export const fetchFreeTerms = async ({
  eventTypeId,
  monthNumber,
  jwtToken
}: FreeTermsData): Promise<FreeTermsRequestResponse> => {
  const response = await apiClient(jwtToken).get(`free-terms/${eventTypeId}/${monthNumber}`).json()

  return freeTermsResponseSchema.parse(response)
}

export interface AvailableHour {
  dateFrom: dayjs.Dayjs
  dateTo: dayjs.Dayjs
  label: string
  doctorId: number
  logicalValue: boolean
}

export interface SerializedHour {
  dateFrom: string
  dateTo: string
  label: string
  doctorId: number
  logicalValue: boolean
}

export interface AvailableTerm {
  eventTypeId: number
  date: string
  availableHours: AvailableHour[]
}

export const parseResponse = (freeTerms: FreeTermsRequestResponse) => {
  const availableTerms: AvailableTerm[] = []
  dayjs.extend(utc)
  dayjs.extend(tz)

  freeTerms.forEach((term) => {
    const dayjsTime = dayjs(term.time).tz('Europe/Warsaw')
    const stringTime = dayjsTime.format(YEAR_MONTH_DAY)
    const newHour: AvailableHour = {
      dateFrom: dayjsTime,
      dateTo: dayjsTime.add(term.duration, 'm'),
      label: dayjsTime.format('HH:mm'),
      doctorId: term.doctor_id,
      logicalValue: false
    }
    const whichTermIndex = findIndex(availableTerms, { date: stringTime })
    if (whichTermIndex < 0) {
      const newTerm: AvailableTerm = {
        eventTypeId: term.type_id,
        date: stringTime,
        availableHours: [newHour]
      }
      availableTerms.push(newTerm)
    } else {
      availableTerms[whichTermIndex].availableHours.push(newHour)
    }
  })
  return availableTerms
}
