import { apiClient } from 'common/utils/api'
import { triggerRegisteredPatientEvent } from 'common/utils/gtm'

import * as z from 'zod'
import { patientPersonalInfoSchema } from './constants'

const createNewAccountSchema = z.object({
  ...patientPersonalInfoSchema,
  nfz: z.number(),
  password: z.string(),
  confirmPassword: z.string(),
  acceptTerms: z.boolean()
})

export type CreateNewAccountData = z.TypeOf<typeof createNewAccountSchema>

const createNewAccountResponseSchema = z.string()

type CreateNewAccountRequestResponse = z.TypeOf<typeof createNewAccountResponseSchema>

export const submitCreateNewAccount = async (
  requestData: CreateNewAccountData
): Promise<CreateNewAccountRequestResponse> => {
  const response = await apiClient()
    .post('Patients', { json: { ...requestData } })
    .json()

  triggerRegisteredPatientEvent(requestData)

  return createNewAccountResponseSchema.parse(response)
}
