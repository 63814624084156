import { apiClient } from 'common/utils/api'
import * as z from 'zod'

const paymentStatusSchema = z.object({
  status: z.string()
})

export type PaymentStatusResponse = z.TypeOf<typeof paymentStatusSchema>

export const getPaymentStatus = async (id: string, jwtToken: string): Promise<PaymentStatusResponse> => {
  const response = await apiClient(jwtToken).get(`Payments/status/${id}`).json()

  return paymentStatusSchema.parse(response)
}
