import { Box, makeStyles } from '@material-ui/core'
import { PendingStepProps, PendingStep } from 'features/summary'

interface PendingStepsListProps {
  steps: PendingStepProps[]
}

export const PendingStepsList = ({ steps }: PendingStepsListProps) => (
  <Box className={useStyle().stepsList}>
    {steps.map((step) => (
      <PendingStep
        key={step.header}
        header={step.header}
        isSuccess={step.isSuccess}
        isLoading={step.isLoading}
        isError={step.isError}
      />
    ))}
  </Box>
)

const useStyle = makeStyles((theme) => ({
  stepsList: {
    width: '65%',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
      width: '100%'
    },

    [theme.breakpoints.down('sm')]: {
      width: '70%'
    }
  }
}))
