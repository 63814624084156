import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react'
import { Link, LinkProps } from 'react-router-dom'

export const LinkText = ({ to, children, ...rest }: React.PropsWithChildren<LinkProps>) => {
  return (
    <Link {...rest} to={to || ''}>
      <span>{children}</span>
    </Link>
  )
}

export const HrefText = ({
  href,
  children,
  ...rest
}: DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => {
  return (
    <a {...rest} href={href || ''}>
      <span>{children}</span>
    </a>
  )
}
