import { Backdrop, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import image from 'features/landingSections/images/greenMeds.svg'
import { TwoColorHeader } from '../TwoColorHeader'

interface IconFullScreenLoaderProps {
  open?: boolean
  headerTranslationPath?: string
}

export const IconFullScreenLoader = ({ open = false, headerTranslationPath }: IconFullScreenLoaderProps) => {
  const classes = useStyle()
  return (
    <Backdrop open={open} className={classes.root}>
      <Grid item className={classes.content} md={4} container>
        {headerTranslationPath && <TwoColorHeader translationPath={headerTranslationPath} />}
        <img alt="Trwa przetwarzanie..." src={image} />
        <CircularProgress size={65} />
      </Grid>
    </Backdrop>
  )
}

const useStyle = makeStyles((theme) => ({
  root: {
    zIndex: 10,
    background: theme.customColors.white,
    position: 'fixed'
  },
  content: {
    width: '100%',
    color: theme.customColors.white,
    margin: '0 auto',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& > img': {
      margin: `${theme.spacing(5)} 0`,
      maxHeight: '30vh',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '50%'
      }
    },

    '& > h1': {
      color: theme.customColors.fontGray,
      textAlign: 'center',
      minHeight: '110px',

      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem'
      }
    }
  }
}))
