/* eslint-disable react-hooks/exhaustive-deps */
import { DAY_MONTH_HOUR_MINUTE_NAME, YEAR_MONTH_DAY, YEAR_MONTH_DAY_MINUTES_SEC } from 'common/constants'
import { numberPercentage } from 'common/utils/percentageMath'
import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import { useUser } from 'state'
import { AnswerPillsWrapper, Button, FormSeparator, QuestionHeader } from 'common/components'
import { useSingleChoiceState } from 'common/hooks/useSingleChoiceState'
import dayjs from 'dayjs'
import { SubmitHandler } from 'react-hook-form'
import { AvailableTerm, PromoCode, PromoCodeFormData, PromoCodeRequestResponse, SerializedHour } from 'features'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { calcDateMonthFromNow, Calendar } from './Calendar/Calendar'
import calendarLogo from '../images/calendar.svg'
import 'dayjs/locale/pl'

type SummaryProps =
  | {
      transactionType: string
      calendarData: AvailableTerm[]
      activeMonth: number
      price: number
      promoPrice: number
      areFreeTermsLoading: boolean
      wereNewTermsFetched: boolean
      onSubmit: () => void
      onPromoCodeSubmit: SubmitHandler<PromoCodeFormData>
      isPromoCodeSubmitError: boolean
      promoCodeData: PromoCodeRequestResponse | undefined
      setMonthToFetch: (value: number) => void
    }
  | {
      transactionType: string
      calendarData?: AvailableTerm[]
      activeMonth: number
      price: number
      promoPrice: number
      areFreeTermsLoading: boolean
      wereNewTermsFetched: boolean
      onSubmit: () => void
      onPromoCodeSubmit: SubmitHandler<PromoCodeFormData>
      isPromoCodeSubmitError: boolean
      promoCodeData: PromoCodeRequestResponse | undefined
      setMonthToFetch: (value: number) => void
    }

export const Summary = ({
  transactionType,
  calendarData,
  isPromoCodeSubmitError,
  price,
  promoCodeData,
  promoPrice,
  wereNewTermsFetched,
  onSubmit,
  onPromoCodeSubmit,
  setMonthToFetch,
  activeMonth
}: SummaryProps) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const { updateUserState } = useUser()
  const [calVal, setCalVal] = useState(dayjs(new Date()))
  const maxDate = calcDateMonthFromNow()

  const { options, chooseOption, changeOptions, currentlySelectedOption } = useSingleChoiceState([])

  useEffect(() => {
    if (currentlySelectedOption) {
      updateUserState({
        selectedOption: {
          dateFrom: currentlySelectedOption.dateFrom?.format(YEAR_MONTH_DAY_MINUTES_SEC),
          dateTo: currentlySelectedOption.dateTo?.format(YEAR_MONTH_DAY_MINUTES_SEC),
          label: currentlySelectedOption?.label,
          doctorId: currentlySelectedOption?.doctorId,
          logicalValue: currentlySelectedOption.logicalValue
        } as SerializedHour
      })
    }
  }, [currentlySelectedOption])

  const formatDate = () => {
    if (currentlySelectedOption) {
      const formattedDate = currentlySelectedOption.dateFrom
      if (formattedDate) {
        const splitDate = formattedDate.locale('pl').format(DAY_MONTH_HOUR_MINUTE_NAME).split(' ')
        splitDate[1] += t('summaryPage.dateFormat')
        return splitDate.join(' ')
      }
    }
    return ''
  }

  useEffect(() => {
    if (calendarData && calendarData.length > 0) {
      const firstActiveTerm = calendarData.find((d) => dayjs(d.date).get('M') + 1 === activeMonth)
      if (firstActiveTerm) {
        setCalVal(dayjs(firstActiveTerm.date))
      }
    } else {
      const currentYear = new Date().getFullYear()
      const currentDay = new Date().getDay()
      const mockDate = new Date(`${currentYear}-${activeMonth}-${currentDay}`)
      setCalVal(dayjs(mockDate))
    }
  }, [activeMonth, wereNewTermsFetched])

  useEffect(() => {
    if (calendarData && calendarData.length > 0) {
      const newHours = calendarData.filter((cal) => cal.date === calVal.format(YEAR_MONTH_DAY))[0]
      if (newHours) {
        changeOptions(newHours.availableHours)
      }
    } else {
      changeOptions([])
    }
  }, [calVal])

  const formattedDate = formatDate()
  const formattedPrice = `${price.toFixed(2).replace('.', ',')} zł`
  const formattedPromoPrice = `${promoPrice.toFixed(2).replace('.', ',')} zł`
  const formattedNumberPercentage = promoCodeData
    ? `-${numberPercentage(price, promoCodeData.offPercentage).toFixed(2).replace('.', ',')} zł`
    : ''

  return (
    <Grid container justifyContent="center">
      <Container className={classes.container} disableGutters maxWidth="md">
        {calendarData && (
          <>
            <QuestionHeader
              headerData={{
                regularStart: t('summaryPage.chooseDate.regularStart'),
                highlightedPart: t('summaryPage.chooseDate.highlightedPart'),
                regularEnd: t('summaryPage.chooseDate.regularEnd')
              }}
              description=""
            />
            <Calendar
              setMonthToFetch={setMonthToFetch}
              maxDate={maxDate}
              value={calVal}
              onChange={setCalVal}
              availableTerms={calendarData}
            />
            <QuestionHeader
              headerData={{
                regularStart: t('summaryPage.chooseHour.regularStart'),
                highlightedPart: t('summaryPage.chooseHour.highlightedPart'),
                regularEnd: t('summaryPage.chooseHour.regularEnd')
              }}
            />
            <div className={classes.answerPills}>
              <AnswerPillsWrapper options={options} toggleOption={chooseOption} />
            </div>
          </>
        )}
        <Grid className={classes.bottomSectionWrapper}>
          <Typography className={classes.header} variant="h1">
            {t('summaryPage.summary')}
          </Typography>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container direction="column">
                <Grid item>
                  <Grid container direction="row">
                    <img src={calendarLogo} alt="calendar-logo" />
                    <Typography className={classes.dateLabelText} variant="body1">
                      {t('summaryPage.date')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography className={classes.dateText} variant="body1">
                    {formattedDate}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <PromoCode
                isPromoCodeApplied={!!promoCodeData}
                isSubmitError={isPromoCodeSubmitError}
                onSubmit={onPromoCodeSubmit}
              />
            </Grid>
          </Grid>
          <Grid className={classes.summaryWrapper} container direction="column">
            <Grid className={classes.grayedOut} container justifyContent="space-between">
              <Typography variant="h3">{transactionType}</Typography>
              <Typography variant="h3">{formattedPrice}</Typography>
            </Grid>
            {promoCodeData && (
              <Grid className={classes.grayedOut} container justifyContent="space-between">
                <Typography variant="h3">{promoCodeData.displayName}</Typography>
                <Typography variant="h3">{formattedNumberPercentage}</Typography>{' '}
              </Grid>
            )}
          </Grid>
          <FormSeparator />
          <Grid className={classes.sum} container justifyContent="space-between">
            <Typography variant="h3">{t('summaryPage.toPay')}: </Typography>
            <Typography className={classes.toPay} variant="h3">
              {formattedPromoPrice}
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={8}>
              <Button
                disabled={!currentlySelectedOption}
                fullWidth
                color="primary"
                handleButtonClick={onSubmit}
                variant="contained"
              >
                {t('summaryPage.submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  )
}

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: `${theme.spacing(5)} 0`
  },
  answerPills: {
    padding: `0 0 ${theme.spacing(10)}`
  },
  header: {
    padding: `0 0 ${theme.spacing(5)} 0`,
    textAlign: 'center'
  },
  dateLabelText: {
    fontWeight: 700,
    fontSize: 'clamp(1.3em, 1.3vw, 1.6em)',
    margin: theme.spacing(1)
  },
  dateText: {
    fontWeight: 700,
    margin: 0,
    whiteSpace: 'pre-line',
    fontSize: '1rem',
    width: '75%',

    [theme.breakpoints.down('sm')]: {
      width: '50%',
      padding: `0 0 0 ${theme.spacing(4)}`,
      margin: `0 0 ${theme.spacing(4)} 0`
    },

    [theme.breakpoints.down('xs')]: {
      width: '90%'
    },

    '& > span': {
      color: theme.palette.primary.main
    }
  },
  bottomSectionWrapper: {
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(2)}`
    }
  },
  summaryWrapper: {
    margin: `${theme.spacing(5)} 0 0`
  },
  grayedOut: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap'
    },

    '& > h3': {
      fontWeight: 700,
      fontSize: 'clamp(1.5em, 1.5vw, 1.7em)',
      color: theme.customColors.distinguishedLightGray,

      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        width: '50%',

        '&:nth-of-type(2)': {
          textAlign: 'right'
        }
      }
    },

    '&:nth-of-type(2)': {
      margin: `${theme.spacing(2)} 0 ${theme.spacing(4)} !important`
    },

    '&:last-of-type': {
      margin: `0 0 ${theme.spacing(4)}`
    }
  },
  sum: {
    '& > h3': {
      fontWeight: 700
    },
    margin: `${theme.spacing(3)} 0`,

    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(1.5)} 0 ${theme.spacing(3)}`
    }
  },
  toPay: {
    color: theme.palette.primary.main
  }
}))
