import { apiClient } from 'common/utils/api'
import * as z from 'zod'

const internalEventSchema = z.object({
  userId: z.string(),
  dateFrom: z.string(),
  dateTo: z.string(),
  eventTypeId: z.number(),
  doctorId: z.number(),
  jwtToken: z.string()
})

const internalEventResponseSchema = z.string()

export type InternalEventData = z.TypeOf<typeof internalEventSchema>
export type InternalEventResponse = z.TypeOf<typeof internalEventResponseSchema>

export const createInternalEvent = async (requestData: InternalEventData): Promise<InternalEventResponse> => {
  const { jwtToken, ...rest } = requestData
  const response = await apiClient(jwtToken)
    .post('Events', {
      json: {
        ...rest
      }
    })
    .json()
  return internalEventResponseSchema.parse(response)
}
