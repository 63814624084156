import { Dayjs } from 'dayjs'
import { useState } from 'react'

export interface SingleChoiceOptionType {
  label: string
  logicalValue: boolean
  styledLabel?: JSX.Element
  dateFrom?: Dayjs
  dateTo?: Dayjs
  doctorId?: number
}

export interface TogglableSingleChoiceOption extends SingleChoiceOptionType {
  isChecked: boolean
}

const propagateLabels = (options: SingleChoiceOptionType[]) => {
  return options.map((option) => ({
    ...option,
    isChecked: false
  }))
}

export function useSingleChoiceState(inputOptions: SingleChoiceOptionType[]) {
  const freshOptions = propagateLabels(inputOptions)
  const [options, setOptions] = useState(freshOptions)

  const resetOptions = propagateLabels(options)

  // TODO: rename to smth more descriptive
  const [selectedOption, setSelectedOption] = useState<boolean | undefined>(undefined)
  const [currentlySelectedOption, setCurrentlySelectedOption] = useState<TogglableSingleChoiceOption | undefined>(
    undefined
  )

  const changeOptions = (changedInputOptions: SingleChoiceOptionType[]) => {
    const changedOptions = propagateLabels(changedInputOptions)

    setOptions(changedOptions)
  }

  const chooseOption = (index: number) => {
    const newOptions = [...resetOptions]

    newOptions[index].isChecked = !newOptions[index].isChecked
    setOptions(newOptions)
    setSelectedOption(newOptions[index].logicalValue)
    setCurrentlySelectedOption(newOptions[index])
  }

  return {
    options,
    changeOptions,
    selectedOption,
    currentlySelectedOption,
    setSelectedOption,
    chooseOption
  }
}
