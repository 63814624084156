import { apiClient } from 'common/utils/api'
import { PromoCodeFormData } from 'features'
import * as z from 'zod'

const promoCodeResponseSchema = z.object({
  id: z.string(),
  displayName: z.string(),
  couponCode: z.string(),
  offPercentage: z.number(),
  isCouponForNewUser: z.boolean()
})

export type PromoCodeRequestResponse = z.TypeOf<typeof promoCodeResponseSchema>

interface PromoCodeRequest extends PromoCodeFormData {
  token: string
}

export const submitPromoCode = async ({ promoCode, token }: PromoCodeRequest): Promise<PromoCodeRequestResponse> => {
  const response = await apiClient(token).get(`Coupons/${promoCode}`).json()

  return promoCodeResponseSchema.parse(response)
}
