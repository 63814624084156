import TagManager from 'react-gtm-module'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GTM = (event: string, parameters: any, userId?: string | null) => {
  let gtmParams = { ...parameters }

  if (userId !== null && userId !== undefined && userId !== '') {
    gtmParams = {
      ...gtmParams,
      user_id: userId
    }
  }

  TagManager.dataLayer({
    dataLayer: {
      event,
      parameters: {
        session_id: window.sessionStorage.getItem('sessionId'),
        ...gtmParams
      }
    }
  })
}

export const setSessionId = () => {
  if (!window.sessionStorage.getItem('sessionId')) {
    const randomSessionID = Math.floor(Math.random() * 10000000000).toString()
    window.sessionStorage.setItem('sessionId', randomSessionID)
  }
}
