import { CreateNewAccountData } from 'features'
import { getValuesFromPesel } from '../validatePesel'
import { GTM } from './gtm'

interface BasicPersonalInfoArgs {
  first_name?: string
  last_name?: string
  phone?: string
  email?: string
}

interface ComplexPersonalInfoArgs extends BasicPersonalInfoArgs {
  pesel?: string
  street?: string
  house_number?: string
  city?: string
  zip?: string
  voivodeship?: string
  birth_date?: string
  gender?: string
}

interface CouponArgs extends BasicPersonalInfoArgs {
  coupon_code?: string
  off_percentage?: number
  event_type?: number
}

interface VisitArgs extends CouponArgs {
  doctor_id?: number
  start_date?: string
  price?: number
  payment_id?: string
}

interface VisitSelectionArgs extends BasicPersonalInfoArgs {
  event_type?: number
}

export const triggerInterestedEvent = () => {
  GTM('interested', {})
}

export const triggerLeadEvent = (args: BasicPersonalInfoArgs) => {
  GTM('lead', args)
}

export const triggerQualifiedEvent = (args: BasicPersonalInfoArgs) => {
  GTM('qualified', args)
}

export const triggerNotQualifiedEvent = (args: BasicPersonalInfoArgs) => {
  GTM('not_qualified', args)
}

export const triggerRegisteredPatientEvent = (args: CreateNewAccountData) => {
  const { gender, birthDate } = getValuesFromPesel(args.pesel)

  const mappedArgs: ComplexPersonalInfoArgs = {
    first_name: args.firstName,
    last_name: args.lastName,
    phone: args.phone,
    email: args.email,
    pesel: args.pesel,
    street: args.address.street,
    house_number: args.address.houseNumber,
    city: args.address.city,
    zip: args.address.zip,
    birth_date: birthDate,
    gender
  }
  GTM('registered_patient', mappedArgs)
}

export const triggerCouponAppliedEvent = (args: CouponArgs) => {
  GTM('coupon_applied', args)
}

export const triggerPaymentStartEvent = (args: VisitArgs) => {
  GTM('payment_started', args)
}

export const triggerPaymentCompletedEvent = (args: VisitArgs) => {
  GTM('payment_completed', args)
}

export const triggerVisitSelectedEvent = (args: VisitSelectionArgs) => {
  GTM('visit_selected', args)
}

export const triggerLoginEvent = (args: BasicPersonalInfoArgs) => {
  GTM('login', args)
}
