import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RoutesDefinition } from 'routing/constants/RoutesDefinition'
import { FormTextField, DualColorChoiceLink, Button } from 'common/components'
import { LoginFormData, loginFormSchema } from 'features/login/types'
import { Grid, makeStyles } from '@material-ui/core'

interface LoginFormProps {
  onSubmit: SubmitHandler<LoginFormData>
  isProcessing?: boolean
  isError: boolean
}

export function LoginForm({ onSubmit, isProcessing, isError }: LoginFormProps): JSX.Element {
  const classes = useStyle()
  const { t } = useTranslation()

  useEffect(() => {
    if (isError) {
      formMethods.setError('password', { message: 'errors.loginError' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const formMethods = useForm<LoginFormData>({
    reValidateMode: 'onChange',
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className={classes.formWrapper}>
        <FormTextField
          name="email"
          type="text"
          label={t('loginPage.loginForm.email.label')}
          placeholder={t('loginPage.loginForm.email.placeholder')}
        />
        <Grid className={classes.passwordContainer}>
          <FormTextField
            name="password"
            type="password"
            label={t('loginPage.loginForm.password.label')}
            placeholder={t('loginPage.loginForm.password.placeholder')}
          />
          <DualColorChoiceLink
            className={classes.forgetPassword}
            routingPath={RoutesDefinition.resetPassword}
            translationPath="loginPage.loginForm.forgetPassword"
          />
        </Grid>
        <Grid className={classes.buttonWrapper}>
          <Button
            type="submit"
            color="primary"
            fullWidth
            variant="contained"
            disabled={isProcessing}
            className={classes.submitBtn}
          >
            {t('loginPage.loginForm.submitButton')}
          </Button>
        </Grid>
      </form>
    </FormProvider>
  )
}

const useStyle = makeStyles((theme) => ({
  formWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '70%',

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },

    '& > div': {
      padding: `0 0 ${theme.spacing(2)} 0`,

      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    }
  },
  passwordContainer: {
    position: 'relative',
    width: '100%'
  },
  forgetPassword: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  passwordLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  buttonWrapper: {
    width: '90%'
  },
  submitBtn: {
    marginTop: `${theme.spacing(5)}`
  }
}))
