export const RoutesDefinition = {
  landing: process.env.NODE_ENV === 'production' ? 'https://drugly.pl/' : '/',
  root: '/',
  sandbox: '/sandbox',

  survey: '/survey',
  surveyStart: '/survey/start',
  surveyQuestionnaire: '/survey/questionnaire',
  surveySuccess: '/survey/success',
  surveyProcessing: '/survey/processing',
  surveyFail: '/survey/fail',

  account: '/account',
  accountSettings: '/account/settings',
  accountDashboard: '/account/dashboard',
  accountCreate: '/account/create',

  authLogin: '/login',
  logout: '/logout',

  user: '/users',
  resetPassword: '/reset-password',
  resetPasswordSuccess: '/reset-password/success',
  setNewPassword: '/set-new-password',
  verifyEmail: '/verify-email',

  summary: '/summary',
  pending: '/reservation/pending',
  success: '/reservation/success',

  terms:
    'https://uploads-ssl.webflow.com/627e74080c8a48e8bcda0c95/64a6cf79000962bbfb4d2930_Regulamin%20i%20polityka%20prywatno%C5%9Bci.pdf'
} as const
