import { createContext, FC, useContext } from 'react'
import { useLocalStorageState } from 'common/hooks'
import { USER_STORAGE_KEY } from 'common/constants'
import { SerializedHour } from 'features'

interface UserProfile {
  id?: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  role?: string
  created?: string
  updated?: string | null | undefined
  isVerified?: boolean
  eventTypeId?: number
  paymentId?: string
  couponCode?: string
  selectedOption?: SerializedHour
  finalPrice?: number
  isNewUser?: boolean
}

interface UserFunctions {
  updateUserState: (updatedUser: UserProfile) => void
  clearPaymentInfo: () => void
}

type UserContextShape = UserProfile & UserFunctions

const UserContext = createContext<UserContextShape | undefined>(undefined)

export const UserContextProvider: FC = ({ children }) => {
  const [localState, setLocalState] = useLocalStorageState(USER_STORAGE_KEY, {})

  function updateUserState(updatedUser: UserProfile) {
    setLocalState({ ...localState, ...updatedUser })
  }

  function clearPaymentInfo() {
    const { paymentId, selectedOption, couponCode, ...rest } = localState
    setLocalState({ ...rest })
  }

  const ctxValue: UserContextShape = {
    id: localState?.id ?? -1,
    firstName: localState?.firstName ?? '',
    lastName: localState?.lastName ?? '',
    phone: localState?.phone ?? '',
    email: localState?.email ?? '',
    role: localState?.role ?? '',
    created: localState?.created ?? '',
    updated: localState?.updated ?? '',
    isVerified: localState?.isVerified ?? '',
    couponCode: localState?.couponCode ?? '',
    eventTypeId: localState?.eventTypeId ?? 0,
    paymentId: localState?.paymentId ?? '',
    selectedOption: localState?.selectedOption ?? {},
    isNewUser: localState?.isNewUser ?? true,
    updateUserState,
    clearPaymentInfo
  }

  return <UserContext.Provider value={ctxValue}>{children}</UserContext.Provider>
}

export function useUser() {
  const userCtx = useContext(UserContext)
  if (userCtx === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return userCtx
}
