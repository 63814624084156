// import documentIcon from 'common/icons/document_icon.svg'
import doctorIcon from 'common/icons/doctor_icon.svg'
import { TFuncKey } from 'react-i18next'

export const eventTypes = {
  types: [
    {
      id: 7,
      name: 'Długa, konsultacja dla nowych klientów.',
      duration: 15,
      price: 120,
      icon: doctorIcon,
      title: 'accountDashboardPage.tiles.first.title' as TFuncKey,
      description: 'accountDashboardPage.tiles.first.description' as TFuncKey,
      alt: 'accountDashboardPage.tiles.first.title' as TFuncKey,
      isForNewUsers: true
    },
    {
      id: 8,
      name: 'Długa, konsultacja dla obecnych klientów.',
      duration: 10,
      price: 90,
      icon: doctorIcon,
      title: 'accountDashboardPage.tiles.additional.title' as TFuncKey,
      description: 'accountDashboardPage.tiles.additional.description' as TFuncKey,
      alt: 'accountDashboardPage.tiles.additional.title' as TFuncKey,
      isForNewUsers: false
    }
    // {
    //   id: 9,
    //   name: 'Krótka, recepta dla obecnych klientów.',
    //   duration: 5,
    //   price: 80,
    //   icon: documentIcon,
    //   title: 'accountDashboardPage.tiles.prescription.title' as TFuncKey,
    //   description: 'accountDashboardPage.tiles.prescription.description' as TFuncKey,
    //   alt: 'accountDashboardPage.tiles.prescription.title' as TFuncKey,
    //   isForNewUsers: false
    // }
  ]
}
