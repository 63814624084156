import { apiClient } from 'common/utils/api'
import * as z from 'zod'

const authenticateUserSchema = z.object({
  email: z.string(),
  password: z.string()
})

type AuthenticateUserData = z.TypeOf<typeof authenticateUserSchema>

export const authenticatedUserResponseSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string(),
  role: z.string(),
  created: z.string(),
  updated: z.string().nullish(),
  isVerified: z.boolean(),
  jwtToken: z.string(),
  isNewUser: z.boolean()
})

export type AuthenticatedUserResponseData = z.TypeOf<typeof authenticatedUserResponseSchema>

export const submitLoginForm = async (requestData: AuthenticateUserData) => {
  const response = await apiClient()
    .post('Accounts/authenticate', {
      json: {
        ...requestData
      },
      credentials: 'include'
    })
    .json()

  return authenticatedUserResponseSchema.parse(response)
}
