import { z } from 'zod'
import { phoneRegExp } from 'common/constants'

const isUppercase = (val: string) =>
  val.length > 1 ? val[0] === val[0].toLocaleUpperCase() : val === val.toLocaleUpperCase()

export const startingContactDataForm = z.object({
  firstName: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty('errors.required')
        .min(3, { message: 'errors.min3' })
        .safeParse(val)

      if (val && !isUppercase(val)) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'errors.uppercase' })
      }

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0])
      }
    }),
  phone: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z.string().nonempty('errors.required').regex(phoneRegExp).safeParse(val)

      if (!fieldValidationResult.success) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'errors.incorrectPhoneNumber' })
      }
    }),
  lastName: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty('errors.required')
        .min(3, { message: 'errors.min3' })
        .safeParse(val)

      if (val && !isUppercase(val)) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'errors.uppercase' })
      }

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0])
      }
    }),
  email: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty('errors.emailRequired')
        .email('errors.incorrectEmail')
        .safeParse(val)

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0])
      }
    }),
  termsAndConditions: z.boolean().superRefine((val, ctx) => {
    if (!val) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'errors.termsAndConditionsRequired' })
    }
  }),
  ageDeclaration: z.boolean().superRefine((val, ctx) => {
    if (!val) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'errors.ageRequired' })
    }
  })
})

export type StartingContactDataForm = z.infer<typeof startingContactDataForm>
